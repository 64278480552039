body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  background-color: #fff;
  color: #333;
}

h1,
h2,
h3 {
  color: darkcyan;
  border-bottom: 1px solid silver;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 3rem;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.header .contact-info {
  flex: 0.4;
}

.header .contact-info p {
  font-size: 0.8rem;
  line-height: 0.9rem;
}

.header .contact-info p:first-child {
  color: #666;
  font-size: 1.75rem;
}

.Menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
}

header .Menu {
  border-top: 1px solid silver;
  border-bottom: 1px solid silver;
  background-color: lightblue;
}

footer .Menu {
  border-top: 1px solid silver;
}

.Menu span,
.Menu a {
  text-decoration: none;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
}

.one-third {
  flex: 0.31;
}

.two-thirds {
  flex: 0.63;
}

.one-half {
  flex: 0.45;
}

.full-width {
  flex: 1;
}

.row {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.client-list {
  font-size: 0.9rem;
}

.client-list ul {
  list-style-type: none;
}

img.float-right {
  float: right;
  padding-left: 1rem;
}

.home-header-square {
  background-color: #666;
  color: #efefef;
  padding: 1rem;
}

.home-header-image {
  background: url("./images/marine_slide4.png");
  background-clip: border-box;
  padding: 2rem;
  color: #efefef;
}

.home-header-image p {
  max-width: 75%;
}

.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
}

input[type="text"],
textarea {
  font-size: 0.9rem;
  padding: 0.2rem;
  font-family: Arial, Helvetica, sans-serif;
  width: 50%;
}

textarea {
  width: 75%;
}

button {
  background-color: green;
  color: #efefef;
  border: none;
  padding: 0.3rem;
}

a {
  color: cadetblue;
}

th {
  text-align: left;
}

td {
  vertical-align: top;
  padding: 0.25rem
}

tr.stripeOnHover:hover td {
  background: silver;
  color: white
}

tr.disabledProduct td {
  color: silver;
}

@media screen and (max-width: 768px) {
  .row {
    flex-direction: column;
  }

  .one-half,
  .two-thirds,
  .one-third {
    flex: 1;
  }

  .container {
    padding: 1rem;
  }

  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  header .Menu {
    display: none;
  }
}